<template>
  <b-card
    v-if="data"
    no-body
    class="card-developer-meetup border-primary shadow"
    :style="customStyle"
  >
    <div v-if="data.banner && data.banner.length>0" class="bg-light-primary rounded-top text-center shadow">
      <b-img
        :src="data.banner"
        :alt="data.title"
        class="img-fluid"
      />
    </div>
    <b-card-body class="d-flex flex-column">
      <b-row class="mb-1">
        <b-col cols="12" :md="!location ? 12 : 5">
          <b-media no-body >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="w-100">
              <h6 class="mb-0">
                {{ $date(data.scheduledStart).format('ddd, MMM DD, YYYY')}}
              </h6>
              <small>{{ $date(data.scheduledStart).format('hh:mm a') }} to {{ $date(data.scheduledEnd).format('hh:mm a') }}</small>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12" md="4" class="mt-1 mt-md-0" v-if="location">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34" >
                <feather-icon icon="MapPinIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="w-100">
              <h6 class="mb-0 text-capitalize">
                {{ data.location }}
              </h6>
              <small>{{sessionLabel==='Join Session' ? 'Live' : sessionLabel==='Watch Session' ? 'On Demand' : sessionLabel==='Recording Awaited' ? 'Recording Awaited' : 'Upcoming'}}</small>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12" md="3" class="mt-1 mt-md-0" v-if="location">
          <b-button v-if="sessionLabel && sessionLabel==='Join Session'" size="sm" variant="outline-primary" :href="'/'+data.location" class="float-right">{{sessionLabel}}</b-button>
          <b-button v-if="sessionLabel && sessionLabel==='Watch Session'" size="sm" variant="outline-primary" :href="'/'+data.location+'/'+data.slug" class="float-right">{{sessionLabel}}</b-button>
        </b-col>
      </b-row>
      <!-- session header -->
      <div class="meetup-header flex-grow-1 d-flex align-items-start">
        <div class="meetup-day">
          <h6 class="mb-0 text-uppercase">
            {{ $date(data.scheduledStart).format("ddd")}}
          </h6>
          <h3 class="mb-0">
            {{ $date(data.scheduledStart).format("DD")}}
          </h3>
        </div>
        <div class="">
          <b-card-title v-if="title" class="mb-25">
            {{ data.title }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ data.subTitle }}
          </b-card-text>
          <div v-if="description" v-html="data.description" />
        </div>
      </div>
      <!--/ metting header -->

      <!-- avatar group -->
      <b-avatar-group
        size="48px"
        v-if="speakers && speakers.length>0"
      >
        <b-avatar
          v-for="speaker in speakers"
          :key="speaker.id"
          v-b-tooltip.hover.bottom="speaker.name"
          :src="speaker.avatar"
          class="pull-up border-secondary"
          :button="true"
          @click="showParticipantDetail(speaker)"
        />
          <h6 class="align-self-center cursor-pointer ml-1 mb-0">
        </h6>
      </b-avatar-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    speakers() {
      return this.$store.getters['attendees/sessionSpeakers'](this.data.speakerList)
    },
    sessionLabel() {
      let label = null
      // console.log('this.data.id', this.data);
      const sessionStart = this.$date(this.data.scheduledJoin || this.data.scheduledStart)
      const sessionEnd = this.$date(this.data.scheduledEnd)
      if (this.$date().isBefore(sessionStart)) {
        label = null
      } else if (this.$date().isBetween(sessionStart, sessionEnd, null, '[]')) {
        label = 'Join Session'
      } else if (this.$date().isAfter(sessionEnd) && this.data.recordingUrl) {
        label = 'Watch Session'
      } else if (this.$date().isAfter(sessionEnd) && !this.data.recordingUrl) {
        label = 'Recording Awaited'
      }
      // console.log('sessionlabel', label, sessionStart.toString(), sessionEnd.toString());
      return label
    }
  },
  methods: {
    showParticipantDetail(speaker) {
      console.log('showParticipantDetail', this.showSpeakerInfo, speaker);
      if (this.showSpeakerInfo) {
        this.$store.dispatch('attendees/showSidebar', {show: true, data: speaker, chat: false, call: false, meeting: false})
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: Boolean,
      default: () => false,
    },
    description: {
      type: Boolean,
      default: () => false,
    },
    showSpeakerInfo: {
      type: Boolean,
      default: () => true,
    },
    location: {
      type: Boolean,
      default: () => false,
    },
    customStyle: {
      type: String,
      default: () => ''
    }
  },
}
</script>
